<template>
  <div class="container-privacy">
    <v-container style="max-width: 1200px">
      <v-row class="align-center ma-0 py-10 d-flex justify-space-between">
        <div class="align-center d-flex">
          <img :src="googleAdsLogo" @click="clickAdLogo" />
          <a
            href="/terms-of-use"
            class="ml-16 pl-16 mt-2 unvisible-in-mobile"
            >{{ $t("login_card.agree_terms_text_link") }}</a
          >
        </div>
        <!--        <select-language-component :authorized="true"/>-->
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>{{ $t("privacy_page.title") }}</h1>
        </v-col>
        <v-col md="12">
          <v-row style="border-top: 1px solid #000000">
            <v-col md="3"
              ><span>{{ $t("privacy_page.update_date") }}</span></v-col
            >
            <v-col md="7">
              <p>{{ $t("privacy_page.p1") }}</p>
              <p>{{ $t("privacy_page.p2") }}</p>
              <p>{{ $t("privacy_page.p3") }}</p>
              <h4>{{ $t("privacy_page.headline1") }}</h4>
              <p>{{ $t("privacy_page.headline1_p") }}</p>
              <p>{{ $t("privacy_page.p4") }}</p>
              <p>{{ $t("privacy_page.p5") }}</p>
              <p>{{ $t("privacy_page.p6") }}</p>
              <p>{{ $t("privacy_page.p7") }}</p>
              <p>{{ $t("privacy_page.p8") }}</p>
              <p>{{ $t("privacy_page.p9") }}</p>
              <p>{{ $t("privacy_page.p10") }}</p>
              <p>{{ $t("privacy_page.p11") }}</p>
              <p>{{ $t("privacy_page.p12") }}</p>
              <p>{{ $t("privacy_page.p13") }}</p>
              <p>{{ $t("privacy_page.p14") }}</p>
              <p>{{ $t("privacy_page.p15") }}</p>
              <p>{{ $t("privacy_page.p16") }}</p>
              <h4>{{ $t("privacy_page.headline2") }}</h4>
              <p>{{ $t("privacy_page.headline2_p") }}</p>
              <p>{{ $t("privacy_page.p17") }}</p>
              <p>{{ $t("privacy_page.p18") }}</p>
              <p>{{ $t("privacy_page.p19") }}</p>
              <p>{{ $t("privacy_page.p20") }}</p>
              <p>{{ $t("privacy_page.p21") }}</p>
              <p>{{ $t("privacy_page.p22") }}</p>
              <p>{{ $t("privacy_page.p23") }}</p>
              <p>{{ $t("privacy_page.p24") }}</p>
              <p>{{ $t("privacy_page.p25") }}</p>
              <p>{{ $t("privacy_page.p26") }}</p>
              <p>{{ $t("privacy_page.p27") }}</p>
              <h4>{{ $t("privacy_page.headline3") }}</h4>
              <p>{{ $t("privacy_page.headline3_p") }}</p>
              <h4>{{ $t("privacy_page.headline4") }}</h4>
              <p>{{ $t("privacy_page.headline4_p") }}</p>
              <h4>{{ $t("privacy_page.headline5") }}</h4>
              <p>{{ $t("privacy_page.headline5_p") }}</p>
              <h4>{{ $t("privacy_page.headline6") }}</h4>
              <p>{{ $t("privacy_page.headline6_p") }}</p>
              <h4>{{ $t("privacy_page.headline7") }}</h4>
              <p>{{ $t("privacy_page.headline7_p") }}</p>
              <h4>{{ $t("privacy_page.headline8") }}</h4>
              <p>{{ $t("privacy_page.headline8_p") }}</p>
              <h4>{{ $t("privacy_page.headline9") }}</h4>
              <p>{{ $t("privacy_page.headline9_p") }}</p>
              <h4>{{ $t("privacy_page.headline10") }}</h4>
              <p>{{ $t("privacy_page.headline10_p") }}</p>
              <h4>{{ $t("privacy_page.headline11") }}</h4>
              <p>{{ $t("privacy_page.headline11_p") }}</p>
              <h4>{{ $t("privacy_page.headline12") }}</h4>
              <p>{{ $t("privacy_page.headline12_p") }}</p>
              <div>
                <div>{{ $t("privacy_page.footer_p1") }}</div>
                <div>{{ $t("privacy_page.footer_p2") }}</div>
                <p>{{ $t("privacy_page.footer_p3") }}</p>
              </div>
              <p class="pb-16">{{ $t("privacy_page.support_email") }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Footer bg-color="#b9b9b9" />
  </div>
</template>

<script>
import googleAdsLogo from "@/assets/icon/Pivotadd_black.svg";

export default {
  name: "Privacy",
  components: {
    Footer: () => import("@/components/Footer"),
    // SelectLanguageComponent: () => import("@/components/SelectLanguageComponent")
  },
  data() {
    return {
      googleAdsLogo,
    };
  },
  methods: {
    clickAdLogo() {
      const token = localStorage.getItem("token");
      if (token) {
        this.$router.push("/my-campaigns");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
.container-privacy {
  background: #f2f2f7 !important;
  font-family: "Raleway light", sans-serif !important;
  font-variant-numeric: lining-nums !important;
}
@media screen and (max-width: 450px) {
  .unvisible-in-mobile {
    display: none;
  }
}
</style>
